.main-team {

  .caption {
    margin-top: 1rem;
  }

  .columns {
    margin-bottom: 2rem;

    @include mq("medium") {
      margin-bottom: 0;
    }

    .first-column {
      img {
        max-width: 210px;
        width: 80%;
        margin-bottom: 2rem;

        @include mq("medium") {
          width: 60%;
        }

        @include mq("small") {
          width: 50%;
          margin-top: 2rem;
        }
      }

      a {
        color: $brand-lightgreen;
      }
    }
  }

  .section-title {
    border-top: 1px solid $color-body;
    padding-top: 2rem;
    margin: $vmargin 1rem 3rem;
    font-weight: 300;

    @include mq("small") {
      margin: 3rem 0 2rem;
      padding-left: 0;
    }
  }

  .margin-space {
    margin-bottom: $vmargin;

    @include mq("medium") {
      margin-bottom: 3rem;
    }
  }

  .team-logo-container {
    margin-top: $vmargin;

    @include mq("medium") {
      margin-top: 3rem;
    }

    .team-logos .column[rel="4"] {
      @include mq("small") {
        width: 50%;
        margin-bottom: 2rem;
        padding: 0 1rem;
      }

      a {
        width: 100%;
        display: block;
        border: 1px solid rgba($color-body, 0.5);
        margin-bottom: 1rem;

        img {
          width: 50%;
          margin: 0 auto;
          opacity: 0.6;
          transition: opacity 0.3s ease-out;
        }

        &:hover {
          img {
            opacity: 1;
          }
        }
      }
    }

  }
}