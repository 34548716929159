.is-enter {
  .filter {
    position: fixed;
    top: 0;
  }

  .filter-btn {
    position: fixed;
    top: 0;
  }
}

.is-out {
  .filter {
    position: absolute;
    top: auto;
    bottom: 0;
  }

  .filter-btn {
    position: absolute;
    top: auto;
    bottom: calc(100vh - 7rem);
  }
}

.filter {
  width: 30%;
  height: 100vh;
  padding: calc( 140px + 2rem ) 1rem 3rem calc(1% + 1rem);
  position: absolute;
  left: 0;
  transition: transform 0.3s ease-out;
  z-index: 5;
  background-color: $color-grey-100;

  @include mq('small') {
    width: 100%;
    background-color: $color-grey-100;
    padding: 10rem 4rem 2rem calc(5% + 1rem);
  }

  .input-group {
    margin-bottom: 3rem;

    input {
      &::placeholder {
        color: $color-body;
        font-size: 1.4rem;
        letter-spacing: 1.6px;
      }

      &:-ms-input-placeholder {
        color: $color-body;
        font-size: 1.4rem;
        letter-spacing: 1.6px;
      }

      &::-ms-input-placeholder {
        color: $color-body;
        font-size: 1.4rem;
        letter-spacing: 1.6px;
      }
    }

    .label-text {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: 1.6px;
    }

    .selectize-input {
      color: $color-body;
      border-bottom: 1px solid $color-body;
      font-weight: 300;
      padding-bottom: 0.5rem;

      &.dropdown-active {
        &:after {
          border-color: transparent transparent $color-body transparent;
        }
      }

      &:after {
        border-color: $color-body transparent transparent transparent;
      }
    }
  }

  .elevations-container {
    margin-top: 6rem;
    position: relative;

    @include mq('small') {
      display: none;
    }

    .elevations-img {
      width: 50%;
    }

    button {
      text-align: left;
      position: absolute;
      width: 50%;
      right: 0;
      top: 0;
      font-size: 1.5rem;
      text-transform: uppercase;
      padding-left: 5rem;
      font-weight: 300;
      white-space: nowrap;
      color: $color-body;

      @include mq('medium') {
        padding-left: 4rem;
        font-size: 1.2rem;
      }

      &:hover {
        color: $brand-green;
      }

      &.is-active {
        color: $brand-green;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 3rem;
        height: 1px;
        left: 1rem;
        top: 45%;
        background-color: $color-body;

        @include mq('medium') {
          left: 0.6rem;
        }
      }

      &[data-story='21'] {
        top: 10%;
      }

      &[data-story='10'] {
        top: 30%;
      }

      &[data-story='7'] {
        top: 65%;
      }

      &[data-story='4'] {
        top: 75%;
      }

      &[data-story='3'] {
        top: 85%;
      }
    }
  }

  .filter-search-btn {
    width: 100%;
    padding: 1.2rem 0 1rem;
    margin: 0 auto;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: $brand-green;
    color: #fff;
    display: none;

    @include mq('small') {
      display: block;
    }
  }
}

.filter-btn {
  color: $color-body;
  padding-left: 1rem;
  padding-top: 4rem;
  position: absolute;
  left: 5%;
  z-index: 6;

  &__icon {
    width: 15px;
    height: 15px;
    margin-right: 0.5rem;
    cursor: pointer;
    position: relative;
    transform-origin: center center;
    display: inline-block;
    transition: transform 0.3s ease-out;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $color-body;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 6px;
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        top: 6px;
        transform: rotate(-135deg);
      }
    }
  }
}

.results-container {
  width: 70%;
  min-height: 100vh;
  margin-left: 30%;
  padding-top: 3rem;
  background-color: #fff;
  border-top: 1px solid $color-grey-300;
  border-bottom: 1px solid $color-grey-300;
  transition: all 0.3s ease-out;

  @include mq('small') {
    width: 100%;
    min-height: 0;
    margin-left: 0;
  }

  .current-filter-type {
    color: $brand-green;
    padding: 0 0 1.6rem 5%;
    border-bottom: 1px solid $color-grey-300;
    text-transform: capitalize;

    @include mq('small') {
      padding: 0 0 1.6rem 16rem;
      font-size: 3rem;
    }
  }

  .result-sorry {
    padding: 2rem 0 1.6rem 3%;
  }
}

.results {
  display: flex;
  flex-wrap: wrap;
  padding-right: calc(5% + 1rem);
  padding-left: 2%;

  @include mq('small') {
    padding: 0;
    margin: 0 auto;
    width: 90%;
  }
}

.result {
  width: 33.33%;
  position: relative;
  padding: 6rem 0 3rem;

  @include mq('small') {
    width: 50%;
  }

  // &[data-featured="1"] {
  //   .result__info__unit {
  //     display: none;
  //   }

  //   .result__info__plan {
  //     display: block;
  //   }
  // }

  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10%;

    h1 {
      color: $brand-green;
      font-weight: 300;
      width: 100%;

      @include mq('large') {
        width: 100%;
      }
    }

    &__plan {
      display: none;
    }

    &__meta {
      width: 100%;

      @include mq('large') {
        width: 100%;
        margin-bottom: 1rem;
      }

      span {
        font-size: 1.4rem;
        font-weight: bold;
        display: block;

        @include mq('small') {
          font-size: 1.2rem;
        }
      }
    }
  }

  &__img {
    margin: 32px auto;
    max-height: 200px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: $color-grey-300;
  }

  &:before {
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 80%;
    height: 1px;
  }

  &:after {
    top: 50%;
    right: 0;
    transform: translate3d(0, -50%, 0);
    width: 1px;
    height: 80%;
  }

  &:nth-child(3n) {
    &:after {
      display: none;

      @include mq('small') {
        display: block;
      }
    }
  }

  &:nth-child(2n) {
    &:after {
      @include mq('small') {
        display: none;
      }
    }
  }

  &:nth-last-child(1),
  &:nth-last-child(2),
  &:nth-last-child(3) {
    &::before {
      display: none;
    }
  }

  &:nth-last-child(3) {
    &::before {
      @include mq('small') {
        display: block;
      }
    }
  }
}

// is filter hide
.is-filter-hide {
  .filter {
    transform: translateX(-100%);
  }

  .results-container {
    width: 100%;
    margin-left: 0;

    .current-filter-type {
      padding: 0 0 1.6rem 20rem;
    }
  }

  .filter-btn__icon {
    transform: rotate(45deg);
  }

  .results {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
}
