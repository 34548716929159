.neighbourhood-mainimage {
  margin: 0 auto;
}

.westend-top {
  margin-bottom: 12rem;
}

.westend {
  margin: 12rem auto $vmargin;

  @include mq("small") {
    margin: 6rem auto;
  }

  h1 {
    margin-bottom: 18rem;

    @include mq("medium") {
      margin-bottom: 9rem;
    }

    @include mq("small") {
      margin-bottom: 3rem;
    }
  }

  .columns {
    margin-bottom: 2rem;
    flex-wrap: nowrap;

    @include mq("small") {
      margin-bottom: 0;
      flex-wrap: wrap;
    }
  }

  .margin-btm {
    margin-bottom: 6rem;
  }
}


.westend-essentials {
  position: relative;
  width: calc(90% - 2rem);

  @include mq("small") {
    width: 100%;
    overflow: hidden;
  }

  &__mobile-title {
    display: none;

    @include mq("small") {
      display: block;
      margin-bottom: 2rem;
    }
  }
}

.essentials__contents {
  width: 100%;
  position: absolute;
  left: 0;
  top: 3rem;
  z-index: 10;

  @include mq("large") {
    top: 2.4rem;
  }

  @include mq("medium") {
    top: 2rem;
  }

  h1 {
    margin-bottom: 1.6rem;

    @include mq("medium") {
      font-size: 3.6rem;
      margin-bottom: 1rem;
    }

    @include mq("small") {
      display: none;
    }
  }

  &__list {
    position: absolute;
    left: 0;
    display: none;

    ul li {
      margin-left: 0.4rem;
      font-size: 1.4rem;
      margin-bottom: 1rem;
      font-weight: 300;

      @include mq("large") {
        font-size: 1rem;
      }

      @include mq("medium") {
        margin-left: 0;
        margin-bottom: 0.6rem;
      }

      @include mq("small") {
        font-size: 1.2rem;
      }
    }
  }
}

.essentials__videos {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;

  @include mq("small") {
    display: none;
  }

  a {
    width: 30%;
    position: absolute;
    left: 22%;
    bottom: 7rem;
    z-index: 10;
    opacity: 0;
    visibility: hidden;

    @include mq("large") {
      bottom: 5rem;
    }

    video {
      width: 100%;
    }

    p {
      position: absolute;
      left: 1.4rem;
      bottom: 0.2rem;
      color: #fff;
      font-weight: 300;
      font-size: 1.8rem;
      letter-spacing: 1.6px;

      @include mq("large") {
        font-size: 1.2rem;
      }
    }
  }
}

.essentials__map {
  position: relative;
  width: 100%;

  &__base {
    @include mq("small") {
      width: 200%;
      transform: translateX(-36%);
    }
  }

  .map__pointer {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @include mq("small") {
      width: 200%;
      transform: translateX(-36%);
    }

    &__list {
      display: none;
    }
  }

  .map__clip__container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    box-sizing: content-box;
    border: 1px solid #EDE9E2;
  }

  .map__clip {
    opacity: 0;
    visibility: hidden;
  }

  .map__images {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;

    @include mq("small") {
      display: none;
    }

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__bg {
      opacity: 0;
      visibility: hidden;
      transform: translateZ(1px);
    }
  }
}

.essentials__nav {
  position: absolute;
  left: 0;
  bottom: 3rem;
  z-index: 10;

  @include mq("large") {
    bottom: 2rem;
  }

  .essential-container {
    margin-left: 1.5rem;
    margin-bottom: 1rem;

    @include mq("large") {
      margin-left: 0;
      margin-bottom: 1rem;
    }
  }

  &__list {
    display: flex;
    align-items: baseline;
    color: $brand-green;
    margin-bottom: 0.6rem;
    font-size: 1.6rem;
    letter-spacing: 1.6px;
    opacity: 0;
    transform: translateY(40px);
    transition: color 0.45s;

    @include mq("large") {
      font-size: 1.2rem;
    }

    &.is-active {
      color: $brand-lightgreen;

      span {
        background-color: $brand-lightgreen;
      }
    }

    &:hover {
      color: $brand-lightgreen;

      span {
        background-color: $brand-lightgreen;
      }
    }

    span {
      width: 30px;
      height: 30px;
      background-color: $brand-green;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      transition: background-color 0.45s;

      @include mq("large") {
        width: 22px;
        height: 22px;

        img {
          width: 10px;
        }
      }
    }
  }

  &__plus {
    width: 60px;
    height: 60px;
    background-color: $brand-green;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include mq("large") {
      width: 40px;
      height: 40px;
      margin-left: -8px;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    &:hover {
      &:after {
        transform: translate3d(-50%, -50%, 0) scale(1);
        transition: transform .45s cubic-bezier(.6, .01, 0, 1.38);
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate3d(-50%, -50%, 0) scale(0);
      background-color: $brand-lightgreen;
      width: 66px;
      height: 66px;
      border-radius: 50%;
      transition: transform 0.3s cubic-bezier(.4, 0, 0, 1);

      @include mq("large") {
        width: 44px;
        height: 44px;
      }

      @include mq("medium") {
        display: none;
      }
    }
  }
}


// For IE
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .essentials__map__base {
    height: 100vh;
  }

  .map__clip__container {
    display: none;
  }

  .map__images {
    display: none;
  }

  .essentials__map .map__pointer .map__pointer__list {
    height: 100vh;
  }

  .essentials__contents {
    top: 1rem;
  }

  .essentials__nav {
    bottom: 2rem !important;
  }
}

.main-neighbourhood {
  h1 {
    @include mq("xlarge") {
      font-size: 4rem;
    }
  }
}