.main-single-journal {
  overflow: hidden;

  &__mobiletitle {
    display: none;

    @include mq("medium") {
      display: block;
      margin-bottom: 2rem;
      font-size: 3.2rem;
    }
  }
}

.s-journal-left {
  width: 90%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate3d(-50%, 0, 0);
  z-index: 10;

  @include mq("medium") {
    display: none;
  }

  h1 {
    padding-left: 1rem;
    max-width: 200px;
    font-size: 2.1rem;
  }
}

.s-journal-left.is-enter {
  position: fixed;
  top: 10rem;
}

.s-journal-left.is-out {
  position: absolute;
  top: auto;
  bottom: 0;
}

.s-journal-center {
  width: 75%;
  margin: 0 0 0 25%;
  position: relative;
  z-index: 20;

  @include mq("medium") {
    width: 100%;
    margin: 0;
  }

  figure {
    padding-bottom: 3rem;
    cursor: url("../img/global/icon-plus.svg"), zoom-in;

    @include mq("medium") {
      padding-bottom: 2rem;
    }
  }
}

.s-journal__gallery {
  width: 45%;

  @include mq("medium") {
    width: 100%;
    margin: 0 auto;
  }
}

.s-journal__text {
  width: 30%;

  @include mq("medium") {
    width: 100%;
    margin-top: 3rem;
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: 2.1rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 3rem;

    @include mq("medium") {
      margin-bottom: 2rem;
    }
  }
}

.journal-nav {
  margin-top: 6rem;
  margin-left: 1rem;

  &__list {
    width: 30px;
    height: 100%;
    position: relative;
    margin-bottom: 0.6rem;

    .bg-white {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      opacity: 0;
    }

    a.active {
      .bg-white {
        opacity: 1;
      }
    }
  }
}

.zoom-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 40%;
  height: auto;
  z-index: 130;
  display: none;
  background-color: #fff;

  .zoom-close {
    width: 30px;
    height: 15px;
    position: absolute;
    right: 2rem;
    top: 2rem;
    opacity: 0;

    @include mq("medium") {
      width: 25px;
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $color-body;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 6px;
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        top: 6px;
        transform: rotate(-135deg);
      }
    }
  }

  .zoom-target {
    margin: 0 auto;

    img {
      margin: 0 auto;
      cursor: url("../img/global/icon-minus.svg"), zoom-out;
    }
  }
}

.zoom-bg-white {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: none;
  opacity: 0;
  z-index: 120;
  overflow: hidden;
}


.s-journal-pagenation {
  margin-top: 9rem;
  position: relative;
  z-index: 20;

  @include mq("medium") {
    margin-top: 3rem;
  }

  .s-journal__prev {
    float: left;
    width: 22.5%;

    @include mq("medium") {
      width: 100%;
      margin-top: 3rem;
    }
  }

  .s-journal__next {
    float: right;
    width: 22.5%;

    @include mq("medium") {
      width: 100%;
    }

    a {
      text-align: right;
      font-size: 4.2rem;
      line-height: 1;
      color: $brand-lightgreen;
      transition: color 0.5s ease-out;

      @include mq("medium") {
        font-size: 3rem;
      }

      &:hover {
        color: $brand-green;
      }
    }
  }
}