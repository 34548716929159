// Misc Vars
$max-width: 1400px;
$max-width-narrow: 950px;
$vmargin: 14rem;

// Fonts
$path : "../fonts";

@font-face {
  font-family: 'Neutraface';
  font-weight: 300;
  font-style: normal;
  src: url('#{$path}/NeutraText-Book/NeutraText-Book.eot');
  /* IE9 Compat Modes */
  src: url('#{$path}/NeutraText-Book/NeutraText-Book.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('#{$path}/NeutraText-Book/NeutraText-Book.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('#{$path}/NeutraText-Book/NeutraText-Book.woff') format('woff'),
  /* Pretty Modern Browsers */
  url('#{$path}/NeutraText-Book/NeutraText-Book.ttf') format('truetype'),
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Neutraface';
  font-weight: 100;
  font-style: normal;
  src: url('#{$path}/NeutraText-Light/NeutraText-Light.eot');
  /* IE9 Compat Modes */
  src: url('#{$path}/NeutraText-Light/NeutraText-Light.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('#{$path}/NeutraText-Light/NeutraText-Light.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('#{$path}/NeutraText-Light/NeutraText-Light.woff') format('woff'),
  /* Pretty Modern Browsers */
  url('#{$path}/NeutraText-Light/NeutraText-Light.ttf') format('truetype'),
  /* Safari, Android, iOS */
}

$font-sans-serif: 'Neutraface',
sans-serif;

// Google Material Grey's
$color-grey-50: #FAFAFA;
$color-grey-100: #F5F5F5;
$color-grey-200: #EEEEEE;
$color-grey-300: #E0E0E0;
$color-grey-400: #BDBDBD;
$color-grey-500: #9E9E9E;
$color-grey-600: #757575;
$color-grey-700: #616161;
$color-grey-800: #424242;
$color-grey-900: #212121;

// Brand Colors
$brand-green: #1E7C40;
$brand-lightgreen: #74A81F;
$brand-yellow: #C3FF61;
$brand-grey: #F5F4F0;
$brand-blue: #4184B3;
$brand-black: #3B3A3B;
$color-body: #9B9686;