.vision {
  margin-bottom: 3rem;
  .columns {
    margin-bottom: 3rem;
    @include mq("small") {
      margin-bottom: 0;
    }
    img {
      width: 100%;
    }

    &.items-center {
      align-items: center;
    }
  }

  .columns:first-child {
    .column:last-child {
      @include mq("small") {
        order: 2;
      }
    }
    .column:nth-child(2){
      @include mq("small") {
        order: 3;
      }
    }
  }

  .margin-space {
    margin-bottom: 3rem;
  }
}