.promo-popup{
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  z-index:1000;
  background:rgba(white,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity:0;
  transition:opacity .5s ease, left .5s ease, top .5s ease, width .5s ease, height .5s ease, background .25s;

  &.active{
    opacity:1;
    display: flex;
  }

  &.faded{
    opacity:1;
    background:rgba(white,0);
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
    height: 200px;
    width: 260px;
    .promo-popup__content {
      max-width: 100%;
      max-height: 100%;
      &--wrap .cta,
      &--wrap .title {
        font-size: 2rem;
      }
      &--wrap .title {
        margin: 0 0 2rem;
      }
    }

    @include mq('small') {
      width: 100%;
      height: 100px;
      .promo-popup__content {
        width: 100vw;
      }
      .promo-popup__content--wrap {
        .small-caps-label,
        .title {
          margin-bottom: .25em;
        }
        br {display: none;}
      }
    }

    .btn__close{
      display: none;
    }
  }

  &__content{
    display: flex;
    align-items: center;
    justify-content: center;
    background:$brand-green;
    position: relative;
    width:95vw;
    height:95vh;
    max-width:400px;
    max-height:400px;

    .btn__close{
      position: absolute;
      top:1rem;
      right:1rem;

      i{
        color:white;
        font-size:1.5rem;
        line-height:1em;
      }
    }

    &--wrap{
      .small-caps-label{
        display:block;
        text-transform:uppercase;
        color:$brand-yellow;
        font-size:1.25rem;
        line-height:1em;
        font-weight:900;
        letter-spacing: 2px;
        margin:0 0 2rem 0;
      }

      .title{
        display:block;
        color:white;
        font-size:3rem;
        line-height:1.25em;
        margin:0 0 5rem 0;
      }

      .cta{
        color:$brand-yellow;
        font-size:3rem;
        line-height:1.5em;
        margin:0;

        a{
          color:$brand-yellow;
        }
      }
    }
  }
}

.main-home {
  margin-top: 0;
  padding-top: calc( 140px + 5rem );

  @include mq("small") {
    padding-top: calc( 140px + 2rem );
  }

  &__intro{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:10rem;
    margin:0 0 10rem 0;

    @include mq("small") {
      margin:0 0 2rem 0;
      grid-gap:2rem;
      grid-template-columns: 1fr;
    }

    aside{
      color:$brand-green;
      margin:6rem 0 0 0;

      .wrap{
        max-width:350px;

        @include mq("small") {
          max-width:none;
        }
      }

      h1{
        margin-bottom:2rem;
      }

      .btn--rounded {
        margin:0 0 2rem 0;
      }
    }

    figure{
      text-align:right;
      overflow:hidden;

      img{
        width:100%;
        height:auto;
        display: inline-block;
        margin:0 auto 0 0;
        transform: translate3d(0,0,0) scale(1);
        animation-name: scale;
        animation-duration: 5s;
        animation-iteration-count: 1;
        animation-timing-function: ease;
      }

      @keyframes scale {
        0% { transform: translate3d(0,0,0) scale(1); }
        100% { transform: translate3d(0,0,0) scale(1.1); }
      }
    }
  }







  &__features{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:10rem;
    margin:0 0 5rem 0;

    @include mq("small") {
      grid-template-columns:1fr;
      grid-gap:5rem;
    }

    article{
      display:flex;
      align-items: flex-start;
      flex-wrap:wrap;

      img{
        display: block;
        flex:0 0 100%;
        height:auto;
        margin:0 0 4rem 0;
      }
      h1{
        margin:0 0 2rem 0;
      }
      ul{
        list-style:disc;
        margin:0 0 3rem 2rem;
        flex:0 0 100%;
      }
      li{
        margin:0 0 1rem 0;
      }
      a{
        color:$brand-green;
        text-transform:uppercase;
        font-weight:600;
        font-size:1.4rem;
        line-height:1em;
        letter-spacing: 2px;
        align-self:flex-end;
      }
    }
  }











  &__views{
    width:100vw;
    height:calc( 100vh - 140px );
    overflow:hidden;
    background-size:cover;
    background-position:center;
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover{
      .bg-image{
        transform: translate3d(0,0,0) scale(1.1);
        opacity:0.7;
      }
    }

    .bg-image{
      transition:all 5s ease;
      z-index:-1;
    }

    div{
      text-align:center;
    }

    span{
      display: block;
      color:white;
      font-size:4vw;
      text-align:center;
      width:100%;
      margin:0 0 3rem 0;

      @include mq("small") {
        max-width:400px;
        font-size:3rem;
      }
    }

    a{
      background:white;
      border-radius:20px;
      text-align:center;
      padding:0 5rem;
      color:black;
      text-transform: uppercase;
      font-size:1.1rem;
      height:40px;
      line-height:43px;
      display: inline-block;
      font-weight:600;
      letter-spacing: 2px;
    }
  }







}
