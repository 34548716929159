// Flexbox column system
.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &--baseline {
    align-items: baseline;
  }

  .column {
    width: 100%;
    padding: 0 1rem;

    @include mq("small") {
      padding: 0;
    }

    &.is-empty {
      margin-bottom: 0 !important;
    }

    p {
      width: 90%;
    }

    &[rel="2"] {
      width: 50%;

      @include mq("large") {
        width: 100%;
        margin-bottom: 3rem;
      }

      @include mq("small") {
        margin-bottom: 2rem;
      }
    }

    &[rel="4"] {
      width: 25%;

      @include mq("large") {
        width: 50%;
        margin-bottom: 3rem;
      }

      @include mq("small") {
        width: 100%;
        margin-bottom: 2rem;
      }
    }

  }
}

.full-columns-img {
  margin-top: $vmargin / 2;
  padding: 0 1rem;

  @include mq("small") {
    margin-top: 0;
    margin-bottom: 2rem;
    padding: 0;
  }
}

.column-img {
  position: relative;

  figcaption {
    position: absolute;
    bottom: 3rem;
    left: 4rem;
    color: #fff;
    font-weight: 400;
    letter-spacing: 1px;

    @include mq("small") {
      bottom: 1rem;
      left: 2rem;
    }
  }
}