
.partnerships {

  @include mq("small") {
    margin-top: $vmargin / 2;
  }

  .columns &__left[rel="2"] {
    display: flex;
    flex-direction: column;

    @include mq("medium") {
      margin-bottom: 2rem;
    }
  }

  &__txtcontainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mq("medium") {
      margin-bottom: 2rem;
    }

    h1 {
      width: 40%;

      @include mq("large") {
        width: 100%;
      }
    }

    p.partnerships__txt {
      margin-bottom: 2rem;

      @include mq("large") {
        width: 100%;
      }
    }
  }

  &__imgcontainer {
    figure:first-child {
      margin-bottom: 2rem;
    }
  }

  &__column {
    display: flex;
    margin-top: 2rem;

    @include mq("small") {
      flex-wrap: wrap;
    }

    figure {
      width: 50%;

      @include mq("small") {
        width: 100%;
      }

      &:first-child {
        margin-right: 2rem;

        @include mq("small") {
          margin-right: 0;
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.partnerships-company {
  background-color: #f5f4f0;
  padding: $vmargin / 2 1rem;
  margin-bottom: 6rem;
  margin-top: $vmargin;

  @include mq("small") {
    margin-top: $vmargin / 2;
  }

  h1 {
    color: $brand-green;
  }
  
  p.partnerships__txt{
    color: $brand-green;
    margin-bottom: 7rem;

    @include mq("medium") {
      margin-bottom: 0;
    }
  }

  .column[rel='4'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 5rem 3rem 4rem;

    @include mq("medium") {
      margin-bottom: 0;
    }

    @include mq("small") {
      padding: 3rem 0 2rem;
    }

    img {
      width: 50%;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: $color-grey-400;
    }

    &:before {
      bottom: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: 90%;
      height: 1px;

      @include mq("small") {
        width: 100%;
      }
    }

    &:after {
      top: 50%;
      right: 0;
      transform: translate3d(0, -50%, 0);
      width: 1px;
      height: 90%;

      @include mq("small") {
        display: none;
      }
    }

    &:nth-child(2n) {
      @include mq("medium") {
        &::after {
          display: none;
        }
      }
    }

    &:nth-child(4n) {
      &::after {
        display: none;
      }
    }

    &:nth-child(2) {
      @include mq("small") {
        .line {
          display: none;
        }
      }
    }

    &:nth-child(3),
    &:nth-child(4) {
      @include mq("medium") {
        .line {
          display: none;
        }
      }
    }

    h2 {
      color: $brand-green;
      font-size: 3.2rem;
      margin: 5rem 0;

      @include mq("small") {
        font-size: 1.9rem;
        margin: 3rem 0 2rem 0;
      }
    }

    .line {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: 80%;
      height: 1px;
      background-color: $color-grey-400;

      @include mq("small") {
        width: 100%;
      }
    }
  }

  .view-all-container {
    display: flex;
    justify-content: flex-end;

    .view-all-btn {
      text-transform: uppercase;
      color: $color-body;
      margin-top: $vmargin / 2;

      &:hover {
        color: $brand-green;
      }
    }
  }
}

.is-show-columns {
  .column[rel='4'] {

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(5) {
      img {
        width: 30%;
      }
    }
  }
}

.is-hide-columns {
  display: none;

  &.is-show {
    display: flex;
  }

  .column[rel='4'] {

    &:nth-last-child(1),
    &:nth-last-child(2) {
      img {
        width: 30%;
      }
    }
  }
}