.main-s-floorplans {
  border-top: 1px solid $color-grey-300;

  .close-floorplan-btn {
    display: block;
    width: 45px;
    height: 45px;
    margin: 3rem auto;
    background-color: $brand-green;
    border-radius: 50%;
    position: relative;

    @include mq('small') {
      width: 35px;
      height: 35px;
    }

    &__inner {
      position: absolute;
      width: 40%;
      height: 40%;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #fff;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 50%;
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        top: 50%;
        transform: rotate(-135deg);
      }
    }
  }
}

.s-floorplan {
  small {
    text-align: center;
    display: block;
    margin-top: 3rem;

    @include mq('small') {
      text-align: left;
    }
  }
}

.s-floorplan__info {
  font-weight: 500;
  color: $brand-green;
  text-transform: uppercase;

  @include mq('small') {
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 8rem;

    @include mq('small') {
      font-size: 4rem;
      margin-bottom: 2rem;
    }
  }

  &__plan {
    font-weight: bold;
  }

  &__bedroom {
    font-weight: bold;
    margin-bottom: 2rem;
  }

  &__pdf {
    margin-top: 2rem;
    a {
      color: $brand-lightgreen;
      text-transform: none;
    }
  }
}

.s-floorplan__img {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 3rem;

  &__main {
    width: 90%;
    @include mq('small') {
      width: 100%;
    }
    img {
      margin: 0 auto;
    }
  }
  &__key {
    width: 10%;
    @include mq('small') {
      width: 100%;
    }
    img {
      margin: 0 auto;
    }
  }
}

.viewer {
  cursor: grab;
  margin-bottom: 2rem;

  @include mq('small') {
    margin-bottom: 4rem;
  }

  .swiper-slide {
    width: 200%;

    @include mq('small') {
      width: 300%;
    }
  }

  &__title {
    position: absolute;
    left: 1.8rem;
    bottom: 1rem;
    color: #fff;
    z-index: 5;
    font-weight: 300;
  }

  .viewer-btn-prev {
    position: absolute;
    left: 0;
    width: 100px;
    top: 0;
    bottom: 0;
    height: 50px;
    margin: auto;
    z-index: 5;
    padding-left: 1em;

    &:hover {
      cursor: pointer;
    }

    img {
      margin: auto;
      animation-name: viewer-btn-prev;
      animation-duration: 4s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      transform: translateY(110%) rotate(90deg);

      @include mq('small') {
        width: 80%;
      }
    }
  }

  .viewer-btn-next {
    position: absolute;
    right: 0;
    width: 100px;
    top: 0;
    bottom: 0;
    height: 50px;
    margin: auto;
    z-index: 5;
    padding-right: 1em;

    &:hover {
      cursor: pointer;
    }

    img {
      margin: auto;
      animation-name: viewer-btn-next;
      animation-duration: 4s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      transform: translateY(110%) rotate(-90deg);

      @include mq('small') {
        width: 80%;
      }
    }
  }

  &.views-figure {
    margin-top: 7rem;

    .viewer__title {
      bottom: 3rem;
      left: 4rem;

      @include mq("small") {
        bottom: 1rem;
        left: 2rem;
      }
    }

    @include mq("small") {
      margin-top: 2rem;
    }
  }

  @include mq("small") {
    margin-bottom: 2rem;
  }
}

.views-button {
  display: flex;
  margin-bottom: 7rem;

  .btn {
    background-color: $brand-green;
    color: #fff;    
    margin-right: 0;
    margin-left: auto;
    font-weight: bold;
    border-radius: 20px;
    font-size: 1.2rem;
    letter-spacing: 2px;
    padding: 1.2rem 2.4rem 0.9rem;
    transition: background-color 0.5s;

    &:hover {
      background-color: $brand-lightgreen;
    }

    @include mq("small") {
      padding: 1.2rem 1.6rem 0.8rem;
      font-size: 1rem;
    }
  }

  @include mq("small") {
    margin-bottom: 4rem;
  }
}

// Panoramic Arrows Animation
@keyframes viewer-btn-prev {
  0% {
    margin-left: 20px; 
  }
  50% {
    margin-left: 20px; 
  }
  75% {
    margin-left: -10px;
  }
  100% {
    margin-left: 20px; 
  }
}

@keyframes viewer-btn-next {
  0% {
    margin-right: 20px; 
  }
  50% {
    margin-right: 20px; 
  }
  75% {
    margin-right: -10px;
  }
  100% {
    margin-right: 20px; 
  }
}