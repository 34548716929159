h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.5rem 0;
  line-height: 1.1;
  font-weight: 100;
}

h1 {
  font-size: 4.8rem;
  @include mq("small") {
    font-size: 4rem;
  }
}

h2 {
  font-size: 2.1rem;
  @include mq("small") {
    font-size: 1.9rem;
  }
}

h3 {
  font-size: 1.1rem;
}

h4 {
  font-size: 1rem;
}