// Moving waitlist list form into its' own template.

.waitlist-form {
  &.sign-up {
    background: #1E7C40;
    color: #fff;
    padding: 35rem 0 15rem 0;

    h1 {
      margin-bottom: 2rem;
    }
  }
}

// We need to hide the regular footer for this page!
.minimal-footer {
  margin-top: 0;
  padding-top: 0;

  .startbooking-form {
    display: none;
  }

  &.js-open .footer-bottom,
  .footer-bottom {
    margin-top: 0;
  }
}