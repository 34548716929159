.neighbourhood-page footer {
  margin-top: 0;
}

.page-template-page-thank-you footer {
  margin-top: 0;
  padding: 0;

  .waitlist-form {
    display: none;
  }
}

.front footer {
  margin-top: 0;
  padding: 12rem 0 0;
}

footer {
  margin-top: $vmargin;
  padding: 8rem 0 0;
  color: $brand-green;
  position: relative;

  @include mq('small') {
    padding: 6rem 0 0;
    margin-top: 6rem;
  }

  &:after {
    transform-origin: center bottom;
    transform: scaleY(0);
    transition: transform 0.3s ease-out;
    position: absolute;
    top: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: $brand-green;
    z-index: -1;
  }

  &:hover {
    color: $brand-yellow;

    &:after {
      transform: scaleY(1);
    }

    .footer-reliance svg,
    .footer-rennie svg {
      fill: #fff;
    }
  }
}

footer.js-open {
  color: #fff;

  &:after {
    transform: scaleY(1);
  }

  .waitlist-form__title {
    margin: 0 auto 3rem;
    cursor: auto;
    font-size: 6.4rem;

    @include mq('medium') {
      width: 100%;
    }

    @include mq('small') {
      font-size: 4.6rem;
    }
  }

  .footer-bottom {
    margin-top: 4rem;
  }
}

.footer-bottom {
  // margin-top: 12rem;
  background-color: $brand-green;
  padding: 6rem 0 5rem;
  body.home & {
    padding-right: 320px;// Leaves room for popup box
  }

  @include mq('small') {
    margin-top: 6rem;
    padding: 6rem 0 3rem;
  }

  .flex-box {
    align-items: center;

    @include mq('medium') {
      justify-content: flex-start;
    }
  }

  .footer-reliance {
    width: 100px;

    @include mq('medium') {
      margin-right: 4rem;
    }

    svg {
      fill: #fff;
      transition: fill 0.3s ease-out;
    }
  }

  .footer-rennie {
    width: 80px;

    svg {
      fill: #fff;
      transition: fill 0.3s ease-out;
    }
  }

  .copyright {
    color: #fff;
    font-size: 1.2rem;
    width: 80%;
    transition: color 0.3s ease-out;

    @include mq('medium') {
      margin-top: 2rem;
      width: 100%;
    }
  }
}

.footer-nav {
  margin-top: 2rem;
  text-align: right;

  a {
    font-size: 1.6rem;
    font-weight: bold;
    color: #fff;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }

    & + a {
      margin-left: 2rem;

      &:before {
        content: '/';
        margin-right: 2rem;
      }
    }
  }
}

