*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-size: 62.5%; //10px
}

body {
  font-family: $font-sans-serif;
  font-size: 2.1rem;
  font-weight: 100;
  line-height: 1.4;
  letter-spacing: 0.3px;
  color: $color-body;
  font-variant-numeric: lining-nums;

  @include mq("small") {
    font-size: 1.6rem;
  }
}

a {
  color: $color-body;
  text-decoration: none;
  transition: color 0.6s;

  &:hover {
    color: $brand-green;
  }
}

p {
  margin: 0 0 1.25rem 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ol {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 1rem;
}

button {
  border: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  cursor: pointer;
  background: none;
  outline: none;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

main {
  display: block;
  position: relative;
  margin-top: 20rem;

  @include mq("small") {
    margin-top: 18rem;
  }
}


blockquote {
  margin: 1.25em 0;
  font-weight: 100;
  font-size: 1rem;
  line-height: 1.5em;
}

.margin-top {
  margin-top: 14rem;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .5s ease;
  transform: translate3d(0, 0, 0);
}

.inner-wrap {
  width: 90%;
  // max-width: $max-width;
  margin: 0 auto;
  position: relative;
}

.narrow-wrap {
  width: 90%;
  margin: 0 auto;
  position: relative;
  max-width: $max-width-narrow;
}

.flex-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.clear {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.header-img {
  display: block;
  overflow: hidden; 
  
  img {
    animation: scale 40s linear infinite;
  }
}

// Thank you Page
.main-thankyou {
  p {
    margin: 3rem 0;
  }

  a {
    font-weight: 400;
    letter-spacing: 1px;
    color: $brand-lightgreen;

    &:hover {
      color: $brand-green;
    }
  }
}