.features {
  margin-top: 6rem;
  padding: 6rem 6rem 8rem 6rem;
  background-color: #F5F4F0;

  h1 {
    margin-bottom: 3rem;
    color: $brand-green;
  }

  .bolded {
    color: $brand-green;
    font-weight: bold;
    margin: 2rem auto;
  }

  @include mq('medium') {
    margin-top: 3rem;
    padding: 3rem 0 4rem 0;
  }
}

.image-triggers-title {
  margin:10rem 0 2rem 0;
}

.image-trigger{
  width:100%;
  height:400px;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover{
    .bg-image{
      opacity:0.1;
      transition:opacity 3s ease;
    }
  }

  .bg-image{
    opacity:0.5;
    transition:opacity 1s ease;
  }

  div{
    z-index:3;
  }
  span{
    color:$brand-green;
    line-height:1.5em;
    display: block;
    text-align: center;
    flex:0 0 100%;

    &.label{
      font-size:5rem;
      font-weight:100;
    }
    &.cta{
      font-size:2.5rem;
      font-weight:500;
    }
  }
}


.feature-text {
  text-transform: uppercase;
  color: $brand-green;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 3px;
  margin: 3rem auto;
  display: flex;
  position: relative;
  padding-right: 30px;

  &:hover {
    cursor: pointer;
  }

  @include mq('small') {
    font-size: 1.5rem;
  }
}

.accordion-toggle {
  padding: 0 0 3rem 0;
  border-bottom: 1px solid $color-grey-300;
  overflow: hidden;
  max-height: 80px;

  &--open {
    max-height: 100%;

    .feature-text:before {
      transform: rotate(90deg);
    }
  }

  &:first-of-type {
    border-top: 1px solid $color-grey-300;
  }

  .feature-text:before {
    content: '';
    position: absolute;
    top: 0;
    right: 6px;
    bottom: 0;
    height: 14px;
    width: 2px;
    margin: auto;
    background-color: $brand-green;
  }

  .feature-text:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    width: 14px;
    margin: auto;
    background-color: $brand-green;
  }
}

.feature-list {
  margin-top: 4rem;
}

.feature-item {
  display: flex;
  margin-bottom: 2rem;
}

.feature-icon {
  width: 45px;
  height: 45px;
  margin-right: 2rem;
  min-width: 45px;
}

.feature-label {
  font-size: 2.5rem;
  line-height: 1.5;
  margin: auto auto auto 0;

  &--primary {
    font-weight: 400;
    margin-left: 2rem;
  }
  margin: auto auto auto 0;

  &--secondary {
    margin-left: 4rem;
  }

  @include mq('medium') {
    font-size: 1.8rem;
  }
}
