.page-template-page-thank-you .header__right .btn-waitlist {
  display: none;
}

.is-down header {
  transform: translate3d(0, -140px, 0);
}

.page-template-page-floorplans header {
  position: fixed;
}

.header-label {
  position: absolute;
  height: auto;
  color: #9B9686;
  background: #F5F4F0;
  background: #1e7c40;
  z-index: 200;
  transition: transform 1s, opacity 1s;
  width: 100%;
  top: -30px;
  text-align: center;
  opacity: 0;
  padding:5px 0;

  &.open {
    transform: translateY(30px);
    opacity: 1;
  }

  &-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin: 0 40px;

    @include mq('small') {
      height: auto;
    }

    li {
      // padding: 0 5px;
      display: flex;
      cursor: pointer;
      color: #212121;
      color: white;
      font-weight: 400;
      font-size: 1.75rem;
      letter-spacing: 1px;
      text-transform: none;

      a {
        color: white;
      }
    }
  }
  .close-btn {
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate3d(0,-50%,0);
  }
}

header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 140px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  transition: all 0.6s;
  font-weight: 300;
  background-color: #fff;
  transform: translate3d(0, 0, 0);

  @include mq("small") {
    padding: 3rem 0;
  }

  .flex-box {
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    // margin-top: 30px;
    transition: transform 1s;
  }

  .logo {
    padding-left: 1rem;
    position: relative;
    z-index: 50;

    @include mq("small") {
      padding-left: 0;
    }

    svg {
      width: 110px;
      fill: $brand-green;
      margin-top: 0.5rem;

      @include mq("small") {
        width: 90px;
        margin-top: 1rem;
      }
    }

    p {
      font-size: 1.4rem;
      margin-left: 0.3rem;
      margin-top: 0.2rem;
      margin-bottom: 0;
      color: $brand-lightgreen;
      white-space: nowrap;

      @include mq("small") {
        margin-left: 0;
        margin-bottom: 0.8rem;
        font-size: 1.2rem;
      }
    }
  }

  .btn-waitlist {
    padding:0 2.4rem;
    border-radius: 20px;
    font-size: 1.2rem;
    line-height:33px;
    height:30px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #fff;
    background-color: $brand-green;
    text-transform: uppercase;
    position: relative;
    z-index: 50;
    transition: color 0.5s, background-color 0.5s;
    white-space: nowrap;

    @include mq("small") {
      padding: 0 1.6rem;
      font-size: 1rem;
      white-space: normal;
    }

    &:hover {
      color: #fff;
      background-color: $brand-lightgreen;
    }
  }
}

.hamburger {
  width: 20px;
  height: 15px;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.5s;
  transform-origin: right center;
  display: inline-block;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $color-body;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2) {
      width: 70%;
      margin-left: 30%;
      top: 6px;
    }

    &:nth-child(3) {
      top: 12px;
    }
  }
}

// When the navigation is opened
.is-open {
  .hamburger span {
    &:nth-child(1) {
      top: 6px;
      transform: rotate(135deg);
    }

    &:nth-child(2) {
      opacity: 0;
      left: 60px;
    }

    &:nth-child(3) {
      top: 6px;
      transform: rotate(-135deg);
    }
  }

  .logo {
    svg {
      fill: #fff;
    }

    p {
      color: #fff;
    }
  }

  .btn-waitlist {
    color: $brand-lightgreen;
    background-color: #fff;
  }

  .main-nav li a {
    color: #fff;

    &:hover {
      color: $brand-green;
    }
  }

  .hamburger span {
    background-color: #fff;
  }

  .header__right .menu__container {
    color: #fff;
  }

  .sub-header {
    background-color: $color-body;

    .logo .svg-logo__mobile-container {
      svg {
        fill: #fff;
      }

      p {
        color: #fff !important;
      }
    }

    .btn-waitlist {
      color: $brand-lightgreen;
      background-color: #fff;
    }

    .header__right .menu__container {
      .hamburger span {
        background-color: #fff;
      }

      span {
        color: #fff;
      }
    }
  }
}

.main-nav {
  justify-content: space-between;
  display: flex;
  flex-wrap:wrap;
  margin: 0 2rem;
  justify-content: center;

  @media screen and (max-width: 1380px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  @include mq("small") {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100vh;
    margin-left: 0;
    float: none;
    background-color: $color-body;
    pointer-events: none;
    display: none;
    opacity: 0;
  }

  &.show-more{
    ul:last-child{
      display:flex;
    }
    .btn__more{
      span{
        &:first-child{ display: none; }
        &:last-child{ display: block; }
      }
    }
  }

  &:before{
    content:'';
    position: absolute;
    top:-10%;
    left:-10%;
    width:110vw;
    height:110vh;
    background:$color-body;
    display: none;

    @include mq("small") {
      display: block;
    }
  }

  ul {
    display: flex;
    flex:0 0 100%;
    justify-content: center;

    @include mq("small") {
      position: absolute;
      top: 35%;
      left: 60%;
      display: block;
    }

    &:first-child {
      @include mq("small") {
        left: 10%;
      }
    }

    &:last-child{
      margin:0.75rem 0 0 0;
      display: none;

      @include mq("small") {
        display: block;
        margin:0;
      }
    }
  }

  li {
    font-size: 1.6rem;
    margin-right:2rem;

    &:last-child {
      margin-right: 0;
    }

    @include mq("small") {
      margin-bottom: 2rem;
      font-size: 1.6rem;
    }

    a,
    button {
      color: $color-body;
      transition: color 0.5s;

      &:hover {
        color: $brand-green;
      }

      &.active {
        color: $brand-green;
      }
    }

    .btn__more{
      span{
        &:first-child{ display: block; }
        &:last-child{ display: none; }
      }
    }
  }
}

.header__right {
  text-align: right;

  .menu__container {
    color: $color-body;
    position: relative;
    z-index: 50;
    margin-top: 2rem;
    margin-right: 0.2rem;
    display: none;

    @include mq("small") {
      display: block;
    }

    span {
      margin-right: 0.4rem;
      text-transform: uppercase;
      display: inline-block;
      vertical-align: top;
      letter-spacing: 2px;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  header .logo svg {
    height: 90px;
  }
}
