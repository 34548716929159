// Book a Viewing
// Startbooking embed calendar

.startbooking-form .form-wrapper #startbooking-flow {
  padding-bottom: 10rem;

  // Weekday
  .DayPicker-Weekday {
    color: white;

    * {
      text-decoration: none;
    }
  }

  // modify calendar date blocks
  .DayPicker-Day {
    border: 4px solid $brand-green;
    background-image: url(../img/calendar/calendar-block.png);
    background-size: 100% 100%;
    font-weight: 600;
    font-size: 16px;
    padding-top: 8px;
    transition: color 0.5s, background-color 0.5s;

    &:hover {
      color: $brand-green;
    }

    &:focus {
      outline: none;
    }
  }

  // Disable borders
  .DayPicker-Day--disabled,
  .DayPicker-Day--outside {
    border: none;
    background: none;

    &:hover {
      background-image: none;
      background-color: $brand-green;
      border-radius: 8px !important;
    }
  }

  // modify today's date
  .DayPicker-Day--today {
    background-image: none;
    background-color: $brand-green;
    color: white;

    &:hover {
      color: white;
    }
  }

  // modify selected dates and range
  .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside),
  .DayPicker-Day--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
  .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    background-image: none;
    background-color: $brand-yellow;
    color: $brand-green;
    border-radius: 8px !important;

    &:focus {
      outline: none;
    }
  }

  // White arrows
  .DayPicker-NavButton {
    display: none;
    background-image: url(../img/calendar/arrow.png);

    &--next {
    }

    &--prev {
      transform: rotate(180deg);
    }
  }

  // Adjust arrow position
  .DayPicker-NavBar {
    top: 2.5rem;
  }

  // Filter area
  .cbsb-pull-right {
    // Hide all but the date range
    > *:not(.cbsb-date-range) {
      display: none;
    }
  }

  // Adjust date range text
  .cbsb-filter-bar .cbsb-filter-options .cbsb-date-range {
    float: none;
  }

  // Class heading
  .cbsb-list-day-heading {
    background: none;
    border-top: 3px solid rgba(255, 255, 255, 0.5);
    padding: 1rem 0 0 0;
    font-weight: 600;
    clear: both;
  }

  .cbsb-list-class-instance div.cbsb-split.group-detail {
    font-size: 18px;
    width: 100%;

    // hide Class title
    > div:first-child {
      // padding: 0 0 1rem 0;
      // font-size: 18px;
      // font-weight: 600;
      display: none;
    }

    // Class time
    > div:nth-child(2) {
      font-size: 16px;
    }

    // Hide the instructor
    > div:nth-child(3) {
      display: none;
    }
  }

  // Hide additional deets
  .cbsb-details {
    display: none;
  }

  // join and # remaining adjustment
  .cbsb-list-class-instance div.cbsb-instance-join {
    width: 100%;
    text-align: center;
    margin: auto 0 0 0;
  }

  // Classes per day
  .cbsb-list-class-instance {
    border-bottom: 0;
    position: relative;
    float: left;
    width: 23%;
    margin: 1%;
    border: 2px solid rgba(255, 255, 255, 0.4);
    min-height: 190px;
    flex-direction: column;
    padding: 17px;

    @include mq('xsmall') {
      width: 47%;
    }
  }

  // // This just removes the extra border
  // .cbsb-list-day-heading + .cbsb-list-class-instance {
  // 	border-top: 0;
  // }

  // Class button and form button
  .sb-primary-action button,
  .cbsb-list-class-instance div.cbsb-instance-join button {
    padding: 1.2rem 2.4rem 0.9rem;
    border-radius: 20px;
    font-size: 1.2rem;
    letter-spacing: 2px;
    font-weight: bold;
    color: $brand-green;
    background-color: white;
    text-transform: uppercase;
    position: relative;
    z-index: 50;
    transition: color 0.5s, background-color 0.5s;
    white-space: nowrap;

    @include mq('small') {
      padding: 1.2rem 1.6rem 0.8rem;
      font-size: 1rem;
    }

    &:hover {
      background-color: $brand-yellow;
      opacity: 1;
    }
  }

  // Sold out button
  div.cbsb-instance-join button[disabled] {
    color: $brand-green;
    background: transparent;
    font-size: 0;
    pointer-events: none;
    max-width: 100%;

    &:before {
      content: 'Full';
      color: white;
      display: block;
      font-size: 14px;
    }
  }

  // Your details heading
  .cbsb-group-details-wrapper h2 {
    font-weight: 600;
    padding: 0 0 2rem 0;
  }

  // input field
  .sb-field-group input {
    color: white;

    &::-webkit-input-placeholder {
      /* Edge */
      color: white;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white;
    }

    &::placeholder {
      color: white;
    }
  }

  // Max height to the giant list of available classes
  .cbsb-group-list-wrapper {
    .cbsb-filter-bar + div {
      max-height: 675px;
      overflow: scroll;

      @include mq('small') {
        max-height: inherit;
      }
    }
  }

  // Hide December
  // .DayPicker-Month:last-child {
  // 	display: none;
  // }
}
