.level-btns {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  button {
    font-size: 2.8rem;
    color: $color-body;
    margin-left: 2rem;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color-body;
    }

    &.is-active {
      color: $brand-green;

      &:after {
        background-color: $brand-green;
      }
    }
  }
}

.views-container {
  &--init {
    display: none;
  }

  &.is-active {
    display: block;
  }
}