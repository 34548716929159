.btn {
	text-decoration: none;
	outline:0;
	display: inline-block;
	color:$color-body;
	padding:0 2rem;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size:0.8rem;
	user-select: none;
	outline:0;
	cursor:pointer;

	&.solid{
    background:$color-body;
    color:white;
		border:none;

		&.white{
			background:white;
	    color:$color-body;
		}
	}

	&.outline{
		border:solid 2px $color-body;
		color:$color-body;
		background:transparent;

		&.white{
			border:solid 2px white;
	    color:white;
		}
	}

	&--solid{
		background:$brand-green;
		color:$brand-yellow;
		line-height: 38px;
		height:35px;
		border:none;
		border-radius:0;
		font-weight:bold;
		font-size:1.5rem;
		padding:0 3rem;

		&:hover{
			color:$brand-yellow;
		}
	}

	&--rounded{
		border-radius:20px;
	}

	&--underline{
		color:$brand-green;
		border-bottom:solid 1px $brand-lightgreen;
		font-size:1.75rem;
		font-weight:bold;
		letter-spacing: 0;
		line-height: 2em;
		padding:0;
		text-transform: none;
	}

}
