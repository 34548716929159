.main-journal {
  article {
    margin-bottom: 2rem;
  }
}
.journal-article {
  overflow: hidden;
  position: relative;
  &:hover {
    .journal-article__container {
      &:after {
        transform: translateY(0);
      }
      h2 {
        color: $color-body;
      }
      span {
        color: $brand-lightgreen;
      }
    }
  }

  &__container {
    width: calc(100% - 1.9rem);
    position: absolute;
    left: 50%;
    bottom: 0;
    padding: 2rem 3rem;
    transform: translate(-50%, 0);
    &:after {
      content: '';
      display: block;
      background-color: #F5F4F0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(100%);
      transition: transform 0.3s ease-out;
      z-index: 1;
      @include mq("small") {
        transform: translateY(0);
      }
    }

    @include mq("small") {
      width: 100%;
      position: static;
      transform: translate(0, 0);
      padding: 2rem 2rem;
    }
    h2 {
      color: #fff;
      font-weight: 300;
      transition: all 0.3s ease-out 0.2s;
      position: relative;
      z-index: 2;
      @include mq("small") {
        opacity: 1;
        transform: translateY(0);
        color: $color-body;
      }
    }
    span {
      display: block;
      text-align: right;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: #fff;
      font-weight: bold;
      transition: all 0.3s ease-out 0.3s;
      position: relative;
      z-index: 2;
      @include mq("small") {
        opacity: 1;
        transform: translateY(0);
        color: $brand-lightgreen;
      }
    }
  }
}