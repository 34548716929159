.startbooking-form {
  .wp-block-calendar-booking-default-booking-flow {
    max-width: 1000px;

    h2 {
      color: #fff;
    }

    p {
      font-size: 2rem;
      color: #fff;
    }

    input[type='text'],
    input[type='email'],
    input[type='number'] {
      background-color: #fff;
      color: $color-body;

      &:focus {
        background-color: #fff;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #fff inset;
        -webkit-text-fill-color: $color-body !important;
      }
    }

    #startbooking-block-default > div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      > span {
        display: none;
      }

      .calendar {
        width: 48%;

        @include mq('small') {
          width: 100%;
        }
      }

      .avalible-time-section {
        width: 48%;

        @include mq('small') {
          width: 100%;
        }
      }
    }

    .bottom-panel {
      position: static;
      background-color: transparent;
      border: none;
      padding: 0;
      justify-content: flex-end;
    }

    .DayPicker-Month {
      color: $color-body;
    }

    .DayPicker-Caption,
    .DayPicker-Weekday {
      color: #fff;
    }

    .DayPicker-NavButton--next {
      background-image: none;
      display: block;

      &:after {
        content: '\276F';
        display: block;
      }
    }

    .DayPicker-NavButton--prev {
      background-image: none;
      display: block;

      &:after {
        content: '\276E';
        display: block;
      }
    }

    .DayPicker-NavButton--interactionDisabled {
      &:after {
        display: none;
      }
    }

    .avalible-time-list li {
      color: $brand-green;
      background-color: #fff;
      border-radius: 0;
    }

    .form {
      max-width: 600px;
      background-color: transparent;
      box-shadow: none;
      padding: 0;
    }

    .not-avalible-section {
      border: none;
      margin: 0;

      p {
        margin: 0;
        padding: 0;
      }

      svg {
        fill: #fff;
      }
    }

    [dusk='customer-heading'] {
      width: 100%;
      margin-bottom: 2rem !important;
    }

    // Until startbooking plugin is fixed. Prevent clicking
    [style='color: rgb(91, 99, 106); background: rgb(230, 235, 240); cursor: not-allowed;'] {
      pointer-events: none;
    }

    .button {
      border-radius: 0;
    }

    .note {
      display: none;
    }

    button[type='submit'] {
      color: $brand-green !important;
      background-color: #fff !important;
      border-radius: 0;
    }
  }
}
