.waitlist-form {
  &__title {
    font-size: 14rem;
    margin-bottom: 3rem;
    transition: color 0.3s ease-out;
    cursor: pointer;
    transition: all 0.6s;
    // white-space: nowrap;

    @include mq("medium") {
      font-size: 11rem;
    }

    @include mq("small") {
      font-size: 4.6rem;
    }

    span {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }

  &__text {
    // width: 46%;
    margin-bottom: 6rem;
    font-weight: 300;

    // @include mq("medium") {
    //   width: 80%;
    // }

    // @include mq("small") {
    //   width: 100%;
    // }
  }

  .form-wrapper {

    a {
      color: inherit;
    }

    &:not(.show) {
      display: none;
      opacity: 0;
      visibility: hidden;
      transform: translateY(40px);
    }

    @include mq("medium") {
      width: 100%;
    }

    .form__left,
    .form__right {
      width: 46%;

      @include mq("small") {
        width: 100%;
      }
    }
  }
}

.consent-box {
  margin-top: 3rem;
  width: 80%;
}


// Remove default CSS
input[type="submit"] {
  border: none;
  border-bottom: transparent;
  border-radius: 0;
  background-color: transparent;
  padding: 1.2rem 0;
  font-size: 4.8rem;
  font-weight: 200;
}

input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="text"],
textarea,
select {
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  width: 100%;
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  padding: 0.2rem 0;
  border-radius: 0 !important;
  transition: 0.5s all;
  background-color: transparent;
  -webkit-appearance: none;
  line-height: 1.8;

  &:focus {
    outline: none;
    border-bottom: 1px solid #fff;
  }

  &::placeholder {
    color: $color-grey-700;
  }

  &:-ms-input-placeholder {
    color: $color-grey-700;
  }

  &::-ms-input-placeholder {
    color: $color-grey-700;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1E7C40 inset;
    -webkit-text-fill-color: #fff !important;
  }
}

// Error
.field-group.error {
  color: #ff1a1a;

  input {
    border-bottom: 1px solid #ff1a1a;
  }
}

.input-group.error {
  color: #ff1a1a;

  input[type="checkbox"]+label:after {
    border: 1px solid #ff1a1a;
  }
}

.consent-box.error {
  color: #ff1a1a;

  input[type="checkbox"]+label:after {
    border: 1px solid #ff1a1a;
  }
}


.form-inner {
  font-weight: 300;

  .label-text {
    font-size: 1.6rem;
    letter-spacing: 2px;
    margin-bottom: 1.4rem;
  }

  .input-group {
    margin-bottom: 2rem;
  }

  .checkbox-list.flex-box {
    width: 100%;
    justify-content: flex-start;

    li {
      width: 25%;
      font-size: 1.6rem;

      @include mq("medium") {
        width: 50%;
        margin-bottom: 1rem;
      }

      input {
        margin-right: 1rem;
      }

      label {
        vertical-align: bottom;
      }
    }
  }

  .field-group {
    margin-bottom: 3rem;
    position: relative;

    @include mq("small") {
      width: 100%;
    }

    >label {
      font-size: 1.4rem;
      letter-spacing: 2px;
      margin-bottom: 0.8rem;
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: transform .2s cubic-bezier(.08, .52, .47, .96);
      transform-origin: top left;
    }

    input:required {

      &:focus,
      &:valid {
        +label {
          transform: translate3d(0, -100%, 0) scale(0.8);
        }
      }
    }
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    &+label {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-size: 1.4rem;
      letter-spacing: 2px;
      padding-left: 2.6rem;
    }

    // Box.
    &+label:before {
      position: absolute;
      left: 0;
      top: -2px;
      content: '';
      margin-right: 10px;
      margin-bottom: 4px;
      display: inline-block;
      vertical-align: bottom;
      width: 15px;
      height: 15px;
      border: 1px solid #fff;
      background: #fff;
      transform: scale(0);
      transition: all 0.2s ease;
    }

    &+label:after {
      position: absolute;
      left: 0;
      top: -2px;
      content: '';
      margin-right: 10px;
      margin-bottom: 4px;
      display: inline-block;
      vertical-align: bottom;
      width: 15px;
      height: 15px;
      border: 1px solid #fff;
      transition: all 0.2s ease;
    }

    // Box checked
    &:checked+label:before {
      transform: scale(1);
    }

    // Disabled state label.
    &:disabled+label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled+label:before {
      box-shadow: none;
      background: #ddd;
    }
  }

  // Radio button CSS
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked+label,
  [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 2.5rem;
    cursor: pointer;
    display: inline-block;
    ;
  }

  [type="radio"]:checked+label:before,
  [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1.5px solid #ddd;
    border-radius: 100%;
  }

  [type="radio"]:checked+label:after,
  [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #fff;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }



  input[type="submit"] {
    margin-top: 3rem;
    padding: 1.2rem 6rem 0.9rem;
    border-radius: 30px;
    font-size: 1.6rem;
    letter-spacing: 2px;
    font-weight: bold;
    color: $brand-green;
    background-color: #fff;
    text-transform: uppercase;
    position: relative;
    z-index: 50;
    transition: color 0.5s, background-color 0.5s;
    cursor: pointer;

    @include mq("small") {
      font-size: 1.2rem;
    }

    &:hover {
      color: #fff;
      background-color: $brand-lightgreen;
    }
  }

}

.select-group {
  width: 100%;
  position: relative;

  select {
    display: none;
  }

  .select-field-box {
    border-bottom: 1px solid #fff;

    .selected-text {
      font-size: 1.6rem;
      letter-spacing: 1.6px;
      line-height: 1.8;
      margin-bottom: 0.2rem;
      cursor: pointer;

      span {
        float: right;

        i {
          font-size: 2rem;
          margin-top: 0.6rem;
        }
      }
    }
  }

  .select-lists {
    width: 100%;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 30px;
    z-index: 55;
    display: none;

    li {
      font-size: 1.4rem;
      letter-spacing: 1.6px;
      line-height: 2.2;
      cursor: pointer;
      color: $brand-green;
      padding-left: 1rem;

      &:hover {
        background-color: rgba($color-body, 0.2);
      }

      &.is-active {
        background-color: rgba($color-body, 0.2);
      }
    }
  }
}