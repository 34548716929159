.main-thankyou {

  h2 {
    font-size: 3.2rem;
    margin-top: 6rem;
  }

  h3 {
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-top: 2rem;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 2rem;
  }

  ul {
    margin-bottom: 3rem;
    padding-left: 2rem;
    list-style: disc;

    li {
      margin-bottom: 1rem;
    }
  }
}